<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 60 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Area</label>
                    <Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="filters.provinsi_id" :loading="loadingDropdownProvinsi"
                        :options="dataDropdownProvinsi"
                        optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Area"
                        :filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'filter')"
                        @change="changeHomebase()" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Homebase</label>
                    <Dropdown id="homebase_id"  dataKey="homebase_id" v-model="filters.homebase_id" :loading="loadingDropdownHomebase"
                        :options="dataDropdownHomebase"
                        optionLabel="homebase_name" optionValue="homebase_id" placeholder="Pilih Homebase"
                        :filter="true" :showClear="true" @filter="searchDropdownHomebase($event, 'filter')"
                    />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Produk</label>
                    <Dropdown id="product_id" v-model="filters.product_id" :options="dataDropdownProduct" optionLabel="product_name" optionValue="product_id" placeholder="Pilih Produk" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Frekuensi</label>
                    <Dropdown id="frekuensi" v-model="filters.frekuensi" :options="dataDropdownFrekuensi" optionLabel="name" optionValue="id" placeholder="Pilih Frekuensi" />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownProvinsi: false,
            loadingDropdownHomebase: false,
            loadingDropdownProduct: false,

            // dataDropdown
            dataDropdownProvinsi: null,
            dataDropdownHomebase: null,
            dataDropdownProduct: null,
            dataDropdownFrekuensi: [
                {name: 'Semua Frekuensi', id: 'semua frekuensi'},
                {name: '0', id: '0'},
                {name: '1', id: '1'},
                {name: '>1', id: '>1'},
            ],

            // filter
            filters: {
                provinsi_id: null,
                homebase_id: null,
                product_id: null,
                frekuensi: '1',
            },
            period: [new Date(), new Date()],
            maxDate: null,
        }
    },
    mounted() {
        this.filters.product_id = 1;
        this.searchDropdownProvinsi('');
        this.searchDropdownHomebase('');
        this.initDropdownProduct();
    },
    created(){
    },
    watch: {
        period(){
            //max range 60 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 60);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        initDropdownProduct() {
            setTimeout(() => {

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/productv2',
                    params: {
                        // "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {
                    this.dataDropdownProduct = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/area',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownProvinsi = res.data.data;
                            this.loadingDropdownProvinsi = false;
                        } else if (purpose == null) {
                            this.dataDropdownProvinsi = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeHomebase() {
            this.filters.homebase_id = null;
            this.dataDropdownHomebase = null;
            this.searchDropdownHomebase('');
        },
        searchDropdownHomebase(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dhomebase.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownHomebase = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/homebase',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "provinsi_id": this.filters.provinsi_id,
                    }
                })
                .then(res => {

                    if (purpose == "filter") {
                        this.dataDropdownHomebase = res.data.data;
                        this.loadingDropdownHomebase = false;
                    } else if (purpose == null) {
                        this.dataDropdownHomebase = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

